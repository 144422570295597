
import { faEnvelope, faUser, faBuilding, faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./index.scss"
import { InputWithIcon } from 'shared/ui/InputWithIcon'
import { $user } from '../../../entities/user/model'
import { $form, IUserForm, formSubmitted, handleChange } from '../model'
import { useUnit } from 'effector-react'
import { Button } from 'shared/ui/Button'
import { useEffect, useState } from 'react'

const MyProfileModal = () => {
    const [imageUrl, setImageUrl] = useState<any>()
    const { firstName, lastName, email, company, userImage }: any = useUnit($user);
    const form: IUserForm = useUnit($form)
    useEffect(() => {
        if (form?.image) {
            //@ts-ignore
            setImageUrl(URL.createObjectURL(form.image))
        }
    }, [form?.image])
    return (
        <div className="change-password-modal">
            <div className="pass-header">MY PROFILE</div>
            <div className='profile-image-wrapper'>
                <img src={userImage} alt="" className='profile-image' />
            </div>
            <div className='user-name'>
                <h3>{firstName} {lastName}</h3>
            </div>
            <div className="profile-input-wrrapper">
                <InputWithIcon icon={<FontAwesomeIcon icon={faUser} />} defaultValue={firstName} onChange={handleChange} name='firstName' className='profile-input-style' />

                <InputWithIcon icon={<FontAwesomeIcon icon={faUser} />} defaultValue={lastName} onChange={handleChange} name='lastName' className='profile-input-style' />
            </div>
            <div className="profile-input-wrrapper">
                <InputWithIcon icon={<FontAwesomeIcon icon={faEnvelope} />} defaultValue={company} disabled className='profile-input-style' />
                <InputWithIcon icon={<FontAwesomeIcon icon={faBuilding} />} defaultValue={email} disabled className='profile-input-style' />
            </div>
            <div className='wrrapper'>
                {imageUrl && < img src={imageUrl} alt="" className='profile-upload-image' />}
                {!imageUrl && <div className='fileupload-wrapper'>
                    <span>Click here to uploade photo</span>
                    <InputWithIcon icon={<FontAwesomeIcon icon={faFile} />} type='file' name="image" onChange={handleChange} accept="image/*" className='profile-fileupload-style' label='Click here to uploade photo' />
                </div>}
            </div>
            <div className='profile-button-wrraper'>
                <Button className='profile-button' onClick={formSubmitted}>Save</Button>
            </div>
        </div>
    )
}
export default MyProfileModal