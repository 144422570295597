import "./index.scss"
import ModalUi from "shared/ui/ModalUi"
import { useEffect, useRef, useState } from "react"
import MyProfileModal from "../../features/myProfileModal/ui"
import { faUser, faShield, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUnit } from "effector-react"
import { $user } from "entities/user/model"
import ChangePassowrdModal from "features/changePasswordModal/ui"
import { useClickOutSide } from "shared/hooks/useClickOutSide"
import { Button } from "shared/ui/Button"
import { WAVEUM_LOGIN } from "shared/constants/generic"
import { getApiPrefix, getLangPrefix } from "shared/helpers/genericHelper"
import { $loginState, clearLoginState, logoutFx } from "entities/login/model/tLoginFormModel"
import Login from "pages/Login"
import { Navigate, useNavigate } from "react-router"
interface IProfilePopup {
    closePopup: () => void
}
const ProfilePopup = ({ closePopup }: IProfilePopup) => {
    const [isActiveProfile, setIsActiveProfile] = useState<boolean>(false)
    const [isActiveChangePass, setIsActiveChangePass] = useState<boolean>(false)
    const { userImage, success, lastName, firstName }: any = useUnit($user);
    const { isAuthenticated } = useUnit($loginState);
    const modalRef = useRef(null)
    const navigate = useNavigate()
    const toggleProfilePopup = () => {
        setIsActiveProfile(!isActiveProfile)
    }
    const toggleChangePassModal = () => {
        setIsActiveChangePass(!isActiveChangePass)
    }
    useEffect(() => {
        if (isActiveProfile) {
            setIsActiveProfile(!isActiveProfile)
        }
        // eslint-disable-next-line
    }, [success])
    useClickOutSide(modalRef, closePopup)

    const handleLogOut = () => {
        logoutFx()
    }
    return (

        <div className='profile_popup-conteiner' ref={modalRef}>
            <div className="popup-header">
                <img src={userImage} alt="" className="user-img" />
                <div className="user-info-text">
                    <p>{firstName} {lastName}</p>
                    <p className="changepassword-text" onClick={toggleChangePassModal}>Change password</p>
                </div>
            </div>
            <div className="popup-body flex">
                {/* <div className="body-item" onClick={toggleProfilePopup}>
                    <FontAwesomeIcon icon={faUser} className="profile-icon" />
                    <p className="prifile-text">Profile</p>
                </div>
                <div className="body-item">
                    <FontAwesomeIcon icon={faShield} className="profile-icon" />
                    <p className="prifile-text">Pravicy Policy</p>
                </div>
                <div className="body-item">
                    <FontAwesomeIcon icon={faShield} className="profile-icon" />
                    <p className="prifile-text">Terms of Use</p>
                </div> */}
                <div className="body-item">
                    {/* <FontAwesomeIcon icon={faSignOut} className="profile-icon" />
                    <p className="prifile-text">Sign Out</p> */}
                    <Button className="px-11 bg-red-500 w-full m-2 mb-4 text-white font-bold border-none" onClick={handleLogOut}>Sign Out</Button>
                </div>
            </div>
            <ModalUi isActive={isActiveProfile} onClose={toggleProfilePopup} variant="CENTER">
                <MyProfileModal />
            </ModalUi>
            <ModalUi isActive={isActiveChangePass} onClose={toggleChangePassModal} variant="CENTER">
                <ChangePassowrdModal closeModal={toggleChangePassModal} />
            </ModalUi>
        </div>
    )
}
export default ProfilePopup