import { createEffect } from "effector";
import { IFetchLogisticInventoryRequest, fetchLogisticInventory, fetchStoreInventoryRequestById } from "shared/api/logisticApi";

export const getLogisticInventoryFx = createEffect(async ({ endDate, startDate, }: IFetchLogisticInventoryRequest) => {
  try {
    const data = await fetchLogisticInventory({ endDate, startDate, })
    return data
  } catch (err: any) {
    throw new Error(err.message)
  }
})

export const enum InevntoryRequestStatus {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE"
}
export const getStoreInventoryRequestByIdFx = createEffect(async ({ storeInventoryRequestId }: { storeInventoryRequestId: number }) => {
  try {
    const data = await fetchStoreInventoryRequestById({ storeInventoryRequestId })
    return data
  } catch (err: any) {
    throw new Error(err.message)
  }
})
