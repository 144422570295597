import { InventoryRequestRuleStatus } from "features/logistic/ui/inventoryRequestModals/constants"
import { BaseApi } from "./base"
import { getApiPrefix } from "shared/helpers/genericHelper"
import { BASE_URL } from "shared/constants/generic"
import { sessionStorageSync } from "shared/helpers/storageHelper"

export interface IFetchLogisticInventoryRequest {
    endDate: number | string
    startDate: number | string
}
export const fetchLogisticInventoryRequest = ({ endDate, startDate }: IFetchLogisticInventoryRequest) => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests/rules")
    http.setAdditionalHeaders({ "Language": getApiPrefix() })
    return http.get(`/dashboard?cacheBuster=${+new Date()}&endDate=${endDate}&startDate=${startDate}`)
}
export const fetchLogisticInventory = ({ endDate, startDate }: IFetchLogisticInventoryRequest) => {
    const http = new BaseApi("/api/backend/store-inventory-requests")
    http.setAdditionalHeaders({ "Language": getApiPrefix() })
    return http.get(`/dashboard?cacheBuster=${+new Date()}&endDate=${endDate}&startDate=${startDate}`)
}
export const fetchLogisticDelivery = ({ endDate, startDate }: IFetchLogisticInventoryRequest) => {
    const http = new BaseApi("/api/backend/store-deliveries")
    http.setAdditionalHeaders({ "Language": getApiPrefix() })
    return http.get(`/dashboard?cacheBuster=${+new Date()}&endDate=${endDate}&startDate=${startDate}`)
}
export const fetchLogisticTransfer = ({ endDate, startDate }: IFetchLogisticInventoryRequest) => {
    const http = new BaseApi("/api/backend/store-transfers")
    http.setAdditionalHeaders({ "Language": getApiPrefix() })
    return http.get(`/dashboard?cacheBuster=${+new Date()}&endDate=${endDate}&startDate=${startDate}`)
}
export const fetchStoreInventoryRequestsRulesById = ({ ruleId }: { ruleId: number }) => {
    const http = new BaseApi("/api/backend/store-inventory-requests")
    http.setAdditionalHeaders({ "Language": getApiPrefix() })
    return http.get(`/rules/${ruleId}?`)
}
export const fetchStoreDeliveriesProducts = ({ storeDeliveryId }: { storeDeliveryId: number }) => {
    const http = new BaseApi("/storeservice/api/backend/store-deliveries")
    http.setAdditionalHeaders({ "Language": "fr_FR" })
    return http.get(`/products?cacheBuster=${+new Date()}&storeDeliveryId=${storeDeliveryId}`)
}
export const fetchStoreTransfersProducts = ({ storeTransferId }: { storeTransferId: number }) => {
    const http = new BaseApi("/storeservice/api/backend/store-transfers")
    http.setAdditionalHeaders({ "Language": getApiPrefix() })
    return http.get(`/products?cacheBuster=${+new Date()}&storeTransferId=${storeTransferId}`)
}
export const fetchStoreStocksTables = ({ storeCode }: { storeCode: number }) => {
    const http = new BaseApi("/storeservice/api/backend/store-stocks")
    http.setAdditionalHeaders({ "Language": getApiPrefix() })
    return http.get(`/dashboard?cacheBuster=${+new Date()}&storeCode=${storeCode}`)
}
export const fetchUpdateStoreInventory = ({ storeCode, barcode, stock }: { storeCode: number, barcode: number, stock: number }) => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests")
    http.setAdditionalHeaders({ "Language": getApiPrefix() })
    return http.post(`/update-store-inventory?cacheBuster=${+new Date()}&barcode=${barcode}&stock=${stock}&storeCode=${storeCode}`, {})
}
export const fetchUpdateStoreTransits = ({ body }: { body: { storeCodes: any[] } }) => {
    const http = new BaseApi("/storeservice/api/backend/store-transits")
    http.setAdditionalHeaders({ "Language": getApiPrefix() })
    return http.post(`/dashboard?cacheBuster=${+new Date()}`, body)
}
export const fetchStoreInventoryRequestById = ({ storeInventoryRequestId }: { storeInventoryRequestId: number }) => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests")
    http.setAdditionalHeaders({ "Language": getApiPrefix() })
    return http.get(`/products?cacheBuster=${+new Date()}&storeInventoryRequestId=${storeInventoryRequestId}`)
}
export interface IFetchUpdateStoreInventoryRequestStatus {
    logisticStatus: string,
    storeCode: number,
    storeInventoryRequestId: number

}
export const fetchUpdateStoreInventoryRequestStatus = ({ logisticStatus, storeCode, storeInventoryRequestId }: IFetchUpdateStoreInventoryRequestStatus): Promise<any> => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests")
    return http.put(`/update-store-inventory-request-status?cacheBuster=${+new Date()}&logisticStatus=${logisticStatus}&storeCode=${storeCode}&storeInventoryRequestId=${storeInventoryRequestId}`, {})
}
export const fetchUpdateStoreInventoryRequestBarcodeStock = () => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests")
    return http.post(`/update-store-inventory-request-barcode-stock?cacheBuster=${+new Date()}`)
}
export const fetchStoreInventoryRequestByStoreType = ({ country, storeType }: { country?: string, storeType: string }) => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests")
    http.setAdditionalHeaders({ "Language": "fr_FR" })
    return http.get(`/stores?${country ? `&country=${country}` : ''}&storeType=${storeType}`)
}
export const fetchStoreInventoryRequestAttributes = ({ language }: { language: string, }) => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests")
    // http.setAdditionalHeaders({ "Language": "fr_FR" })
    return http.get(`/attributes?&language=${language}`)
}
export const fetchStoreInventoryRequestAttributesValues = ({ attributeId, language, brandId }: { language: string, attributeId: number, brandId: number | null }) => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests")
    // http.setAdditionalHeaders({ "Language": "fr_FR" })
    return http.get(`/attribute-values?&attributeId=${attributeId}&brandId=${brandId}&language=${language}`)
}
export const fetchCreateStoreInventoryRequestRule = ({ body }: { body: Record<string, any> }) => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests")
    return http.post(`/rules/create-store-inventory-request-rule?`, body)
}
export const fetchInventoryRequestsRuleById = ({ ruleId }: { ruleId: number }) => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests")
    return http.get(`/rules/${ruleId}?`)

}
export const fetchUpdateStoreInventoryRequestRuleStatus = (
    { status, storeInventoryRequestRuleId }:
        { status: InventoryRequestRuleStatus, storeInventoryRequestRuleId: number }
) => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests/rules")
    return http.put(`/update-store-inventory-request-rule-status?&status=${status}&storeInventoryRequestRuleId=${storeInventoryRequestRuleId}`, {})
}
export const fetchDeleteStoreInventoryRequestRuleStatus = (
    { storeInventoryRequestRuleId }:
        { storeInventoryRequestRuleId: number }
) => {
    const http = new BaseApi("/storeservice/api/backend/store-inventory-requests/rules")
    return http.delete(`/delete-store-inventory-request-rule/${storeInventoryRequestRuleId}?`)
}

export const fetchUpdateShipFromStoreStatus = async (payload: FormData) => {
    try {
        const response = await fetch(`${BASE_URL}/storeservice/api/backend/store-stocks/update-ship-from-store-status?`,
            {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${sessionStorageSync.privateToken}`,
                },
                body: payload
            })
        return await response.json()
    } catch (err: any) {
        if (err instanceof Error) {
            throw new Error(err.message)
        }
    }

}